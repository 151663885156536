import React, { useEffect } from 'react';
import { BaselineStylesProvider } from '@paddl/storybook';
import { initialiseBrowserErrorTracking } from '@paddl/utils-js';
import { func, objectOf, any } from 'prop-types';
import  '../styles/fonts.css'
import { FeatureToggleProvider } from '@paddl/utils-react';

const App = ({ Component, pageProps }) => {
  useEffect(() => {
    initialiseBrowserErrorTracking({
      applicationName: 'public-activity',
      sentryDsn: process.env.SENTRY_DSN,
      nodeEnv: process.env.NODE_ENV
    });

    // Remove the server-side injected jss which is not used in this app
    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles) jssStyles.parentElement.removeChild(jssStyles);
  }, []);

  return (
    <BaselineStylesProvider>
    <FeatureToggleProvider>
      <Component {...pageProps} />
    </FeatureToggleProvider>
    </BaselineStylesProvider>
  );
};

export default App;

App.propTypes = {
  Component: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pageProps: objectOf(any).isRequired
};
